import styled from "styled-components";

import imgBthg from "../../../assets/imgs/partners/bthg.png";

const Wrapper = styled.div`
    margin-bottom: 89px;
    .title {
        font-weight: bold;
        font-size: 1.5rem;
        margin-bottom: 34px;
        text-align: center;
    }

    .slide {
        border: 1px solid rgba(90, 202, 235, 1);
        padding: 21px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .slide-item {
            height: 43px;
            margin: 0 34px;
            img {
                height: 100%;
            }
        }
    }
`;

const trustedPartners = [
    {
        img: imgBthg,
        link: "https://baotinhoanggia.com",
    },
];

export default function TrustedBy() {
    return (
        <Wrapper className="trusted-by">
            <div className="title">Trusted by</div>
            <div className="slide">
                {trustedPartners.map((partner) => (
                    <div key={partner.link} className="slide-item">
                        <a href={partner.link} target={"_blank"}>
                            <img src={partner.img} />
                        </a>
                    </div>
                ))}
            </div>
        </Wrapper>
    );
}
